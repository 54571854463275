import { getContact } from "@/api/services/ContactService";
import { Contact } from "@/models/Contact";
import { getInvite } from "@/api/services/InviteService";
import { Vue, Options } from "vue-class-component";
import { Invite } from "@/models/Invite";

@Options({})
export default class Panelist extends Vue {
  contact: Contact | undefined = Contact.createDefault()
  invite: Invite | null = null
  loading = false

  get id(): string {
    return this.$route.query.id as string;
  }

  get status(): string {
    return this.$route.query.status as string;
  }

  get isShowJoinBlock(): boolean {
    return !this.contact?.isPanelist;
  }

  get type(): string {
    return this.$route.query.is_legacy as string
  }

  get typeIsEz(): boolean {
    return this.type === "true"
  }

  async created() {
    if (!this.id) {
      return false
    }

    if (this.typeIsEz) return

    this.loading = true
    this.contact = await getContact(this.id)
    this.loading = false

    this.getInvite()
  }

  async getInvite() {
    const invite = await getInvite(this.id)

    if (invite) {
      this.invite = invite
    }
  }
}
