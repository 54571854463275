
import { Options, Vue } from "vue-class-component"
import { RedirectSimpleQuery, goToPortal } from "@/api/services/InviteService"
@Options({
  props: {
    title: {
      type: String,
      default: "Get more targeted surveys, Join our Panel!"
    },
    button: {
      type: String,
      default: "Click here to sign up"
    },
    npi: {
      type: String,
      default: ""
    }
  }
})
export default class Join extends Vue {
  title: string
  button: string
  npi?: string

  onClick() {
    const query: RedirectSimpleQuery = this.npi ? { npi: this.npi } : {}
    goToPortal(query)
  }
}
